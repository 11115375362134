<template>
  <div class="relative h-2 rounded-full bg-white dark:bg-[#1b2022]">
    <div v-if="inFlux">
      <div class="bg-diagonal w-full rounded-full absolute top-0 h-2" />
    </div>

    <div v-else>
      <!-- Progress Bar -->
      <div
        :style="`width: ${progressBar}%; z-index: 3`"
        class="absolute relative top-0 left-0 h-2 rounded-full bg-primary-600 animate-fade"
      />

      <!-- Candy Bar -->
      <div
        v-if="currentStep != totalSteps"
        class="bg-diagonal rounded-full absolute top-0 h-2 animate-fade-left"
        :style="`left: ${candyBar}%; width: ${candyBarWidth}%; z-index: 1;`"
      />

      <!-- Next Position Dot -->
      <div
        v-if="currentStep != totalSteps"
        :style="`left: ${nextStepPosition}%; z-index: 5`"
        class="h-[1.2rem] w-[1.2rem] rounded-full bg-primary-600 border-4 border-white shadow-lg absolute -top-[0.35rem] animate-fade-right"
      />
    </div>
  </div>
</template>

<script setup>
  const props = defineProps({
    currentStep: {
      type: Number,
      required: false,
      default: 0,
    },

    inFlux: {
      type: Boolean,
      required: false,
      default: false,
    },

    totalSteps: {
      type: Number,
      required: false,
      default: 0,
    },
  })

  const stepWidth = Math.floor(100 / parseInt(props.totalSteps))

  // progressBar
  const progressBar = computed(() => {
    if (props.currentStep == 0) return 3
    return stepWidth * props.currentStep
  })

  // candyBar
  const candyBar = computed(() => {
    if (props.currentStep === 0) return 2
    return (props.currentStep / props.totalSteps) * 100 - 1
  })

  const candyBarWidth = computed(() => {
    if (props.currentStep === 0) return 7
    return stepWidth + 2
  })

  // nextStepPosition
  const nextStepPosition = computed(() => {
    if (props.currentStep === 0) return 8
    // return ((props.currentStep + 1) / props.totalSteps) * 100
    return stepWidth * (props.currentStep + 1)
  })
</script>

<style lang="scss" scoped>
  .bg-diagonal {
    background: repeating-linear-gradient(
      135deg,
      #fff,
      #fff 5px,
      #ea1662 5px,
      #ea1662 10px
    );
  }
</style>
