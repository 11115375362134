<template>
  <div
    class="flex flex-col gap-4 bg-cool-200 rounded-xl px-5 py-4 dark:bg-black"
    :class="greenLight ? 'bg-lime-100 dark:bg-[#213905]' : ''"
  >
    <div class="flex flex-row items-center justify-between dark:text-[#4B575D]">
      <div
        class="flex flex-row items-center gap-2"
        :class="greenLight ? 'text-green-600' : ''"
      >
        <font-awesome-icon :icon="icon" class="text-lg" />
        <span class="font-bold leading-none">
          {{ title }}
        </span>
      </div>

      <span v-if="showSteps">
        {{ stepsDisplay }}
      </span>
    </div>

    <ProgressBarStep
      v-if="showBar"
      :current-step="displayCurrentStep"
      :in-flux="inFlux"
      :total-steps="displayTotalSteps"
    />

    <div class="flex flex-col gap-2">
      <slot />
    </div>
  </div>
</template>

<script setup>
  const props = defineProps({
    currentStep: {
      type: Number,
      required: false,
      default: 0,
    },

    greenLight: {
      type: Boolean,
      required: false,
      default: false,
    },

    icon: {
      type: String,
      required: false,
      default: "fa-light fa-heart-circle-check",
    },

    inFlux: {
      type: Boolean,
      required: false,
      default: false,
    },

    showBar: {
      type: Boolean,
      default: true,
    },

    showSteps: {
      type: Boolean,
      default: true,
    },

    title: {
      type: String,
      required: true,
    },

    totalSteps: {
      type: Number,
      required: false,
      default: 0,
    },

    zeroIndex: {
      type: Boolean,
      required: false,
      default: true,
    },
  })

  const stepsDisplay = computed(() => {
    return props.currentStep == 0
      ? "Let's get started!"
      : `Step ${props.currentStep} of ${props.totalSteps}`
  })

  const displayTotalSteps = computed(() => {
    // return props.zeroIndex ? props.totalSteps - 1 : props.totalSteps
    return props.totalSteps
  })

  const displayCurrentStep = computed(() => {
    // return props.zeroIndex ? props.currentStep : props.currentStep + 1
    return props.currentStep
  })
</script>
