import { defineStore } from "pinia"

const defaultUserState = {
  clientSessionId: null,
  clientSessionToken: null,
  clientFormUrl: null
}

export const useYotiStore = defineStore('yoti', {
  state: () => ({ ...defaultUserState }),
  getters: {
    sessionId: (state) => state.clientSessionId,
    sessionToken: (state) => state.clientSessionToken,
    formUrl: (state) => state.clientFormUrl,
  },
  actions: {
    reset() {
      Object.assign(this, defaultUserState)
    },
    setSessionId(id) {
      this.clientSessionId = id
    },
    setSessionToken(token) {
      this.clientSessionToken = token
    },
    setFormUrl(url) {
      this.clientFormUrl = url
    },
  }
})
