<template>
  <div class="panel-component">
    <!-- Panel: Header w/ Label -->
    <div
      v-if="!$slots.header && label"
      class="panel-header"
      v-bind="{ ...$props, ...$attrs }"
    >
      <span v-if="label" class="label">{{ label }}</span>
      <div v-if="collapsible" class="action">
        <font-awesome-icon
          @click="toggle"
          :icon="['fa-solid', collapsibleIcon]"
          class="cursor-pointer text-gray-400"
        />
      </div>
    </div>

    <!-- Panel: Header Slotted wo/ Label -->
    <div v-if="$slots.header && !label" class="w-full">
      <!-- Collapsible -->
      <div v-if="collapsible" class="panel-header">
        <slot name="header" />
        <div v-if="collapsible" class="action">
          <font-awesome-icon
            @click="toggle"
            :icon="['fa-solid', collapsibleIcon]"
            class="cursor-pointer text-gray-400"
          />
        </div>
      </div>
      <!-- Not Collapsible -->
      <div v-else>
        <slot name="header" />
      </div>
    </div>

    <!-- Panel: Body -->
    <transition-group name="collapse">
      <div v-if="!collapsed" class="panel-content">
        <slot />
      </div>
    </transition-group>
  </div>
</template>

<script setup>
  const props = defineProps({
    label: {
      type: String,
      default: null,
    },
    collapsible: {
      type: Boolean,
      default: false,
    },
    opened: {
      type: Boolean,
      default: true,
    },
  })

  const collapsed = ref(!props.opened)

  const collapsibleIcon = computed(() => {
    if (collapsed.value) {
      return "fa-chevron-down"
    } else {
      return "fa-chevron-up"
    }
  })

  const toggle = async () => {
    collapsed.value = !collapsed.value
  }
</script>

<style lang="scss" scoped>
  .panel-component {
    @apply w-full flex flex-col gap-4 p-4 bg-white rounded-xl;
    @apply dark:bg-[#1B2022];
  }
  .panel-header {
    @apply flex flex-row items-center justify-between;

    .action {
      @apply flex flex-row items-center gap-2;
    }
  }
  .panel-content {
    @apply w-full flex flex-col grow gap-4;
  }
  .label {
    @apply uppercase text-[#595858] text-sm tracking-[0.1ch];
    @apply dark:text-[#555F63];
  }
  .collapse-enter-active,
  .collapse-leave-active {
    transition: opacity 0.1s ease;
  }

  .collapse-enter,
  .collapse-leave-to {
    opacity: 0;
  }
</style>
